.room-number-hero-image-style {
  object-fit: cover;
  height: 30vh;
  width: 100%;
}

.room-number-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  padding: 10px 0;
  margin: 2% 5%;
}

.room-number-container-wrapper {
  margin: 2% 5%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.room-number-subtitle {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100% !important;

  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.input-inner-wrapper {
  width: 50% !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-number-style {
  width: 300px;
  /* max-width: 300px !important; */
  border-radius: 8px;
  line-height: 25px;
  padding: 4px 10px;
  border: 1px black solid;
  margin-top: 26px;
  height: 35px !important;

  font-size: 23px;
}

.input-select {
  width: 320px !important;
  /* max-width: 300px !important; */
  border-radius: 8px;
  line-height: 25px;
  padding: 4px 10px;
  border: 1px black solid;
  margin-top: 26px;
  height: 44px !important;

  font-size: 23px;
}

.select-hotel-option {
  width: 300px;
  max-width: 300px !important;
}

.input-error-msg {
  color: red;

  text-align: start;
  width: 300px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#button-format {
  margin-top: 40px;
}

@media (min-width: 900px) and (max-width: 1430px) {
  .input-wrapper #button-format {
    margin-left: 20px;
  }

  #button-format {
    margin-left: 50px !important;
  }

  .input-wrapper {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: flex-start;
    align-items: flex-start;

    justify-content: flex-start;

    margin-top: 26px;
  }
}

@media (min-width: 1430px) {
  .input-wrapper {
    display: flex;
    flex-direction: row;
    width: 60% !important;
    justify-content: flex-start;
    align-items: flex-start;

    justify-content: flex-start;

    margin-top: 26px;
  }

  #button-format {
    position: absolute;

    left: 800px;
  }
}

@media (min-width: 900px) {
  .room-number-main-info-wrapper {
    height: 61vh;
  }

  .room-number-hero-image-style {
    height: 15vh;
  }

  .room-number-container-wrapper {
    margin: 1% 5% !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .room-number-title {
    font-size: 35px;
    font-weight: 500;
    line-height: 30px;
    padding: 10px 0;
    margin: 2% 5%;
  }

  .room-number-subtitle {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
  }

  .input-inner-wrapper {
    width: fit-content !important;

    display: flex;
    flex-direction: column;
  }

  .input-wrapper #button-format {
    margin-top: 5px;
  }

  .input-error-msg {
    text-align: center;
    margin-top: 5px;
    margin-left: 10px !important;
    width: 450px !important;
  }

  .input-number-style {
    width: 600px !important;
    margin-top: 0px;
    padding-left: 20px;

    font-size: 18px;
  }

  .input-select {
    width: 631px !important;
    margin-top: 20px;
    padding-left: 10px;

    font-size: 18px;
  }
}
