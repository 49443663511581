@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;900&display=swap");

* {
  margin: 0;
  font-family: "Inter", sans-serif;
  --primary100: #07af51;
}

input {
  padding: 0;
  margin: 0;
}
